import { useLocation } from "react-router-dom";
import shajs from "sha.js"

const getParamString = (params = {}) => {
  return Object.entries(params)
    .filter(([key, value]) => {
      const isDefined = value !== undefined;
      const isValidIfString =
        typeof value === "string" ? value.length > 0 : true;

      return isDefined && isValidIfString;
    })
    .map(entry => entry.join("="))
    .join("&");
};

export const getUrl = (baseUrl, params) => {
  return [baseUrl, getParamString(params)].filter(Boolean).join("?");
};

export const getSelfRegistrationUrlForWorkTurn = ({ id, task }) => {
  const hash = shajs("sha256").update(`${task}${id}`).digest("hex")

  return `/events/worker/add/self/${id}?h=${hash}`
}

export const getSelfRegistrationUrlForWorkTurnQr = ({ id, task }) => {
  const hash = shajs("sha256").update(`${task}${id}`).digest("hex")

  return `suvivuorot.fi/events/worker/add/self/${id}?h=${hash}`
}
