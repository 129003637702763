import React from "react";
import WorkTurnListPage from "./pages/WorkTurnListPage"
import LinkListPage from "./pages/LinkListPage"
import GenericNotFound from "./pages/GenericNotFound";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'

function App() {
return (
  <Router basename="/static/app">
    <Routes>
      <Route path="/" element={<LinkListPage/>}/>
      <Route path="/index.html" element={<LinkListPage/>}/>
      <Route path="event/:eventId" element={<LinkListPage/>} />
      <Route path="event/:eventId/church/:churchId" element={<WorkTurnListPage/>}/>
      <Route path="event/:eventId/wt" element={<WorkTurnListPage/>} />
      <Route path="event/:eventId/wt/:typeId" element={<WorkTurnListPage/>} />
      <Route path="*" component={GenericNotFound} />
    </Routes>
  </Router>
)
}

export default App;
